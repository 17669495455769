/* ============
 * Locale
 * ============
 *
 * For a multi-language application, you can
 * specify the languages you want to use here.
 */

import en from './locale/en.json'
import es from './locale/es.json'

const langList = ['ar', 'az', 'bg', 'cs', 'de', 'el', 'en', 'es', 'et', 'fa', 'fi', 'fr', 'he', 'hi', 'hu', 'hy', 'hr', 'id', 'it', 'ja', 'ka', 'kk', 'ko', 'lv', 'lt', 'ms', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'sk', 'sr', 'sv', 'th', 'tr', 'uk', 'vi', 'zh'] as const

export type LangCode = (typeof langList)[number]

const defaultLocales: Partial<Record<LangCode, any>> = {
  en,
  es
}

const LOCALES_MAP: Partial<Record<LangCode, string>> = {
  hi: 'hi_IN',
  ka: 'ka_GE',
  pl: 'pl_PL'
}

async function loadLocale (locale: LangCode) {
  if (!langList.includes(locale)) {
    throw new Error(`Locale ${locale} is not supported`)
  }

  const localeImportName = LOCALES_MAP[locale] ?? locale

  return await import(`./locale/${localeImportName}.json`)
}

export {
  defaultLocales,
  langList,
  loadLocale
}
