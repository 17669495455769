/* ============
 * Vue i18n
 * ============
 *
 * Internationalization plugin of Vue.js.
 *
 * https://kazupon.github.io/vue-i18n/
 */

import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import { reduceKeys } from '@2l/utils'
import { instance } from './axios.js'
import * as Sentry from '@sentry/vue'

import { defaultLocales, loadLocale } from '@2l/locale'

const pluralizationLocaleKeys = (data) => {
  const arr = ['one', 'few', 'many', 'other']
  let str = ''
  for (const i in arr) {
    for (const [key, val] of Object.entries(data)) {
      if (key === arr[i]) { str += `${val} | ` }
    }
  }

  return str.slice(0, -3)
}

function pluralizationLocale (data) {
  return reduceKeys(data, (result, value, key) => {
    if (typeof value === 'object') { result[key] = pluralizationLocaleKeys(value) }

    return data
  }, [])
}

const PluralizationByLanguage = (choice, choicesLength) => {
  if (choice === 0) { return choicesLength - 1 }

  if (choice === 1) { return 0 }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (choicesLength < 4) { return (!teen && endsWithOne) ? 1 : 2 }

  if (!teen && endsWithOne) { return 1 }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) { return 2 }

  return choicesLength - 1
}

const pluralizationRules = {
  ru: PluralizationByLanguage,
  cs: PluralizationByLanguage,
  ar: PluralizationByLanguage,
  he: PluralizationByLanguage,
  id: PluralizationByLanguage,
  pl_PL: PluralizationByLanguage,
  sk: PluralizationByLanguage,
  uk: PluralizationByLanguage
}

const i18nDefaultLocale = defaultLocales[__DEFAULT_LANG__] ? __DEFAULT_LANG__ : 'en'
const i18nDefaultLocaleMessages = defaultLocales[__DEFAULT_LANG__] ?? defaultLocales.en

export const i18n = createI18n({
  locale: i18nDefaultLocale,
  legacy: true,
  messages: {
    [i18nDefaultLocale]: pluralizationLocale(i18nDefaultLocaleMessages)
  },
  globalInjection: true,
  allowComposition: true,
  fallbackLocale: {
    'zh-Hans': ['zh'],
    'zh-Hant': ['zh_hant', 'zh'],
    default: ['en', 'es']
  },
  pluralizationRules
})

const loadLocaleMessages = async function (locale) {
  const messages = await loadLocale(locale)

  i18n.global.setLocaleMessage(locale, pluralizationLocale(messages.default))
  return nextTick()
}

export const setI18nLanguage = async function (lang, activeProfile = '') {
  try {
    if (!i18n.global.availableLocales.includes(lang)) {
      await loadLocaleMessages(lang)
    }

    i18n.global.locale = lang

    // activeProfile as Accept-Language for support languageLevel updates (see answer.js in server project)
    instance.defaults.headers.common['Accept-Language'] = activeProfile || lang
    instance.defaults.headers.common['X-System-Language'] = lang

    return lang
  } catch (err) {
    console.warn(err.message)
    Sentry.captureMessage(err.message, 'warning')
  }
}

export default { i18n, setI18nLanguage }
